import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import { map } from 'lodash';
import get from 'lodash/get';
import Papa from 'papaparse';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AuthContext from '../../components/AuthContext';
import ItemDrawer from '../../components/ItemDrawer';
import useLazyQueryFHG from '../../fhg/components/data/useLazyQueryFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import Grid from '../../fhg/components/Grid';
import ReactSelect from '../../fhg/components/ReactSelect';
import Typography from '../../fhg/components/Typography';
import { b64EncodeUnicode } from '../../fhg/utils/Utils';
import { OPTIONS_QUERY } from '../users/UserInfoEdit';
import Property from './Property';
import PropertyInfoEdit from './PropertyInfoEdit';
import Button from '@material-ui/core/Button';
import Search from '../../fhg/components/Search';
import { ClientImpersonationDropdown } from '../../components/ClientImpersonationDropdown';
import DrawerContext from '../../components/DrawerContext';
import useFullScreen from '../../fhg/hooks/useFullScreen';
import { Redirect } from 'react-router-dom';

export const DEFAULT_REGION = { label: 'All Regions', value: -1 };

const useStyles = makeStyles(
  (theme) => ({
    paperStyle: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.primary.contrastText} !important`,
      },
      width: '100%',
      '& > label': {
        color: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
      },
      '& > div': {
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '& > svg': {
        color: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
        fill: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
        stroke: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
      },
      color: `${
        theme.palette.type === 'dark'
          ? theme.palette.text.primary2
          : theme.palette.primary.contrastText
      } !important`,
      backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
    },
    singleValueStyle: {
      color: `${
        theme.palette.type === 'dark'
          ? theme.palette.text.primary2
          : theme.palette.primary.contrastText
      } !important`,
    },
    selectRoot: {
      color: `${theme.palette.text.primary} !important`,
      '& > svg': {
        color: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
        fill: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
        stroke: `${
          theme.palette.type === 'dark'
            ? theme.palette.text.primary2
            : theme.palette.primary.contrastText
        } !important`,
      },
      marginTop: theme.spacing(1),
      flex: 1,
    },
    button: {
      color: '#fff',
      border: '1px solid #fff',
      width: 40,
      height: 40,
    },
    buttonActive: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#fff',
      width: 40,
      height: 40,
    },
    buttonWrapper: {
      display: 'flex',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '10px',
    },
    searchInput: {
      height: '50px',
      border: '1px solid grey',
      borderRadius: '5px',
      marginLeft: '10px',
      marginRight: '10px',
    },
    filter: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px',
    },
    search: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
      },
    },
    inputRoot: {
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
    textFieldStyle: {
      '& input': {
        color: theme.palette.background.paper,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          '&:focus': {
            width: '100%',
          },
        },
        padding: 8,
      },
    },
    inputInput: {
      margin: '0px !important',
    },
    inputAdornmentStyle: {
      width: '80px',
      height: '50px',
    },
    selectClient: {
      '& .MuiOutlinedInput-input': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiInputLabel-outlined': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.primary.contrastText} !important`,
      },
    },
  }),
  { name: 'PropertyViewStyles' },
);

const PROPERTY_SMALL_FRAGMENT = gql`
  fragment propertySmallInfo on Property {
    id
    name
    isDeleted
    regionId
    templateChangesMade
  }
`;

// The fragment that contains all the properties needed for the Property CSV file.
const PROPERTY_CSV_FRAGMENT = gql`
  fragment propertyCsvInfo on Property {
    companyName
    contactName
    name
    addressLineOne
    addressLineTwo
    city {
      name
    }
    state {
      name
    }
    zipCode
    region {
      name
    }
    email
    phone
  }
`;

export const PROPERTIES_QUERY = gql`
  query getProperties {
    properties: property_All {
      ...propertySmallInfo
    }
  }
  ${PROPERTY_SMALL_FRAGMENT}
`;

// The query to fetch all the properties in the selected region for the CSV file.
export const PROPERTIES_DETAIL_QUERY = gql`
  query getPropertiesDetail($regionId: [Int]) {
    properties: property_AllWhere(propertySearch: { regionId: $regionId }) {
      ...propertyCsvInfo
    }
  }
  ${PROPERTY_CSV_FRAGMENT}
`;

export const USER_REGION_QUERY = gql`
  query getUserRegions($cognitoSub: String) {
    user: user_AllWhere(userSearch: { cognitoSub: [$cognitoSub] }) {
      id
      regionIdList
    }
  }
`;

/**
 * The component that shows a list of properties in the sidebar and the selected property.
 */
function PropertyView({ match }) {
  const classes = useStyles();
  const theme = useTheme();
  const { cognitoSub } = useContext(AuthContext);
  const isFullScreen = useFullScreen();

  const { data: userData, userStatusComponent } = useQueryFHG(USER_REGION_QUERY, {
    variables: { cognitoSub },
  });
  
  const { data, statusComponent } = useQueryFHG(PROPERTIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  
  const [loadPropertyDetail, { detailData, detailStatusComponent }] = useLazyQueryFHG(
    PROPERTIES_DETAIL_QUERY,
    { fetchPolicy: 'network-only' },
    'detail',
  );
  
  const { data: optionsData, optionsStatusComponent } = useQueryFHG(OPTIONS_QUERY);
  const [editValues, handleChange, { defaultValues }] = useEditData(
    { region: DEFAULT_REGION },
    { region: DEFAULT_REGION },
  );

  const [submitPending, setSubmitPending] = useState(false);

  const handleSelectChange = (option, name) => {
    handleChange(undefined, undefined, undefined, { [name]: option }, name);
  };

  const customStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      color: theme.palette.primary.contrastText,
    }),
  };
 
  /**
   * Listens for the changes to the detailData and submitPending which is triggered when the needed detailData is
   * fetched for the CSV file.
   */
  useEffect(() => {
    if (detailData && submitPending) {
      // Change the property names and pull region, city and state up to the top level.
      // Filter out the current property to get its region ID
      
      const propertyList = map(detailData.properties, (property) => ({
        'Property Name': property.name,
        'Company Name': property.companyName,
        'Property Manager': property.contactName,
        Region: get(property, 'region.name', ''),
        'Address 1': property.addressLineOne,
        'Address 2': property.addressLineTwo,
        City: get(property, 'city.name', ''),
        State: get(property, 'state.name'),
        Zip: property.zipCode,
        Email: property.email,
        Phone: property.phone,
        'Region ID': get(property, 'region.id', '')
      }));
      console.log(detailData, "detailData", propertyList)

      const csv = Papa.unparse(propertyList);
      const title = `Contact List for ${
        editValues.region && editValues.region.label
      } ${new Date().toLocaleDateString()}`;
      const fileName = `${title}.csv`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob(csv);
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        let link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8;base64,' + b64EncodeUnicode(csv);
        link.target = '_blank';
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
      }
      setSubmitPending(false);
    }
  }, [detailData]);

  /**
   * The callback when the save is performed to create and save the CSV file.
   */
  const handleSubmit = () => {
    let regionId;

    if (editValues.region && editValues.region.label !== 'All Regions') {
      regionId = get(editValues, 'region.value');
    }
    loadPropertyDetail({ variables: { regionId } });
    setSubmitPending(true);
  };

  const [showFilter, setShowFilter] = useState(false);

  const renderFilter = () => {
    return (
      <div className={classes.filter}>
        <Grid className={classes.buttonWrapper}>
          <Button
            className={showFilter ? classes.buttonActive : classes.button}
            onClick={() => setShowFilter((prev) => !prev)}
          >
            <img
              src={showFilter ? '/images/filter-black.png' : '/images/filter-white.png'}
              alt="filter"
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          </Button>
        </Grid>
        {showFilter && (
          <Grid style={{ margin: '12px' }}>
            <Grid item>
              <Search classes={classes} />
            </Grid>
            {get(userData, 'user[0].regionIdList.length', 0) <= 0 && (
              <Grid item>
                <ReactSelect
                  name={'region'}
                  styles={customStyles}
                  defaultValue={defaultValues.region}
                  classes={{ root: classes.selectRoot, singleValue: classes.singleValueStyle }}
                  className={classes.paperStyle}
                  label={<Typography display={'inline'} id={'propertyInfo.region.label'} />}
                  options={[DEFAULT_REGION, ...(get(optionsData, 'regions') || [])]}
                  value={editValues.region}
                  onChange={handleSelectChange}
                  isMulti={false}
                  fullWidth
                  margin="none"
                />
              </Grid>
            )}
            <ClientImpersonationDropdown
              className={classes.selectClient}
              style={{
                width: '100%',
                height: '50px',
                marginTop: 12,
                marginBottom: 12,
                color: 'white',
              }}
            />
          </Grid>
        )}
      </div>
    );
  };

  const { isDrawerOpen } = useContext(DrawerContext);

  return (
    <Fragment>
      {statusComponent || optionsStatusComponent || userStatusComponent || detailStatusComponent}
      {!isFullScreen && (
        <ItemDrawer
          items={get(data, 'properties')}
          prefixKey={'propertyDrawer'}
          uri={'/property'}
          ItemEdit={PropertyInfoEdit}
          onSubmit={handleSubmit}
          filter={
            editValues.region && editValues.region.label !== 'All Regions'
              ? { regionId: get(editValues, 'region.value') }
              : undefined
          }
          open={isDrawerOpen}
          variant="persistent"
        >
          {renderFilter()}
        </ItemDrawer>
      )}
      <Property key={`propertyView${match.params.id}`} regionIdList={userData?.user[0]?.regionIdList} />
    </Fragment>
  );
}

export default withRouter(PropertyView);
